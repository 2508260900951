import React from 'react';


const A1 = () =>{
    return(
        <g>
        <g>
          {/* <path d="M699,1V699H1V1H699m1-1H0V700H700V0Z"/> */}
          <path class="a" d="M565.1459,321.6956c90.3928,46.4665,101.4019,151.0275,27.9855,235.4972-63.5461,73.1133-220.0665,152.7178-276.2727,11.7245-24.3732-61.14,11.7075-86.5012,42.9394-113.0826,46.6557-39.7084-5.8766-53.9537,15.0039-102.2295C407.5058,277.9938,497.3173,288.6516,565.1459,321.6956Z"/>
        </g>
        <path class="a" d="M309.0663,358.277C310,380,295,399,276,410c-25,14-57,17-81.2685,2.2991a64.7917,64.7917,0,0,1-10-7.2425,63.8533,63.8533,0,0,1-21.7359-48.0784c1.6707-28.3152,12.3658-62.8955,37.2964-75.9505a46.45,46.45,0,0,1,16.7109-5.0227C257,274,290,306,305.9671,339.5574A59.3383,59.3383,0,0,1,309.0663,358.277Z"/>
        <path class="a" d="M404.1466,211.7941c2.0569,19.4952-8.76,23.2631-20.78,27.52-7.9527,2.8166-15.01,7.0214-23.7991,6.1405a40.1973,40.1973,0,0,1-21.0444-8.517c-12.5233-10.3905-8.6476-27.7344-1.7012-40.39,7.61-13.8648,20.2681-24.95,36.9431-22.939C394.295,176.084,402.09,192.2988,404.1466,211.7941Z"/>
      </g>
    )
}

const A2 = () =>{
    return(
            <g>
                {/* <path d="M699,1V699H1V1H699m1-1H0V700H700V0Z"/> */}
    <circle class="a" cx="201" cy="282" r="79"/>
    <circle class="a" cx="376" cy="173" r="44"/>
    <circle class="a" cx="564" cy="634" r="44"/>
    <path class="a" d="M604.7988,393.0691a233.9382,233.9382,0,0,0-7.3235-29.6294,138.9576,138.9576,0,0,0-6.1065-18.8247C576,304,545,270,505,254a128.2867,128.2867,0,0,0-54.2324-8.62,153.34,153.34,0,0,0-27.1773,3.9155C346,277,286,352,260.24,428.6425a139.3683,139.3683,0,0,0-2.3241,26.8346c.4965,52.9694,30.2105,99.4048,86.6205,122.5717,35.1964,13.5782,75.3928,21.1128,110.0595,15.4881a144.0762,144.0762,0,0,0,25.1139-6.4464c35.2417-13.4087,73.7541-26.7546,98.3442-56.2938A102.3678,102.3678,0,0,0,591,511C608.2,475,611.96,433.24,604.7988,393.0691ZM378,570a44,44,0,1,1,44-44A44,44,0,0,1,378,570Z"/>
    <circle class="a" cx="280" cy="601" r="22"/>
    <circle class="a" cx="631" cy="304" r="22"/>
            </g>
    )
}

const A3 = () =>{
    return(
            <g>
                {/* <path d="M699,1V699H1V1H699m1-1H0V700H700V0Z"/> */}
    <path class="a" d="M410.2494,232.0146,208.7545,419.8254c-28.6162,26.6728-17.6272,74.3213,19.78,85.7672l263.3964,80.5943c37.4074,11.4459,73.1777-21.8951,64.3864-60.0138L494.4161,257.768C485.6248,219.6492,438.8656,205.3418,410.2494,232.0146ZM476.0338,399.05l2.0635,8.9472c13.5374,58.6984-41.5445,110.04-99.1475,92.4142l-8.78-2.6866c-57.603-17.6255-74.5248-90.9984-30.4593-132.0713l6.7168-6.2607C390.4926,318.3194,462.4963,340.3511,476.0338,399.05Z"/>
    <circle class="a" cx="252.5" cy="275" r="49.5"/>
    <circle class="a" cx="568.5" cy="253" r="34.2013"/>
    <circle class="a" cx="425.5" cy="616" r="24.6937"/>
    <circle class="a" cx="419.5" cy="427" r="45.5"/>
            </g>
    )
}

const A4 = () =>{
    return(
        <g>
            {/* <path d="M699,1V699H1V1H699m1-1H0V700H700V0Z"/> */}
    <path class="a" d="M587.4032,373.3748,341.71,248.8444c-34.8933-17.6857-75.8469,9.0337-73.7166,48.095l15,275.042c2.13,39.0613,45.7469,61.1685,78.51,39.7929L592.1964,461.2627C624.9593,439.8872,622.2964,391.0605,587.4032,373.3748ZM453.2215,492.6373l-7.69,5.0172C395.08,530.57,327.916,496.528,324.6356,436.3782l-.5-9.1685c-3.28-60.15,59.7834-101.2946,113.5148-74.0607l8.19,4.1512C499.572,384.5342,503.6725,459.7214,453.2215,492.6373Z"/>
    <circle class="a" cx="527.5807" cy="252.6448" r="49.5"/>
    <circle class="a" cx="528.1749" cy="588.1778" r="34.2013"/>
    <circle class="a" cx="232.1346" cy="519.8728" r="24.6937"/>
    <circle class="a" cx="228.1346" cy="403.8728" r="12.3969"/>
    <circle class="a" cx="446.1346" cy="600.8728" r="12.3969"/>
    <circle class="a" cx="575.1346" cy="334.8728" r="12.3969"/>
    <circle class="a" cx="392.5575" cy="403.7017" r="45.5"/>
      </g>
    )
}

const A5 = () =>{
    return(
            <g>
                <path class="a" d="M419.8824,243.5382a175.6076,175.6076,0,0,0-178.8691,222.44,4.8144,4.8144,0,0,0,9.4383-1.025q.0318-.5353.0693-1.0716a100.7309,100.7309,0,0,1,201.0628,12.58c-2.9806,54.2019-48.9678,96.6323-103.2318,95.1678q-1.95-.0527-3.8787-.1779a4.8153,4.8153,0,0,0-2.1592,9.2451,175.1306,175.1306,0,0,0,57.9067,13.2905c96.4525,5.43,179.6426-68.88,185.0862-165.3312A175.5,175.5,0,0,0,419.8824,243.5382Z"/>
      <circle class="a" cx="357.6145" cy="488.4443" r="44"/>
      <ellipse class="a" cx="279.5887" cy="235.9218" rx="24.7733" ry="24.5716" transform="translate(28.428 501.9054) rotate(-86.8001)"/>
      <ellipse class="a" cx="355.9666" cy="625.4076" rx="17.1582" ry="17.3434" transform="translate(-288.3359 945.9089) rotate(-86.8001)"/>
      <ellipse class="a" cx="520.9933" cy="199.5875" rx="44.5091" ry="45.1704" transform="translate(-30.0401 286.945) rotate(-29.9697)"/>
      <circle class="a" cx="578.1393" cy="556.8607" r="20.2879"/>
      <circle class="a" cx="628.0941" cy="308.2615" r="20.2879"/>
      <circle class="a" cx="620.472" cy="372.9369" r="10.3101"/>
      <circle class="a" cx="434.5398" cy="205.2968" r="10.3101"/>
      <circle class="a" cx="152.8731" cy="334.776" r="10.3101"/>
      <circle class="a" cx="277.1684" cy="530.0186" r="10.3101"/>
      <circle class="a" cx="525.8729" cy="596.0042" r="10.3101"/>
            </g>
    )
}

const A6 = () =>{
    return(
        <g>
          <g>
        <circle class="a" cx="263.6087" cy="341.3326" r="29.6098"/>
      <circle class="a" cx="243.0749" cy="509.6094" r="29.6098"/>
      <circle class="a" cx="440.1915" cy="228.5833" r="29.6098"/>
      <circle class="a" cx="556.0927" cy="324.3367" r="16.6469"/>
      <circle class="a" cx="263.3788" cy="454.6198" r="9.1571"/>
      <circle class="a" cx="216.8491" cy="286.267" r="9.1571"/>
      <circle class="a" cx="539.1728" cy="506.2254" r="9.1571"/>
      <circle class="a" cx="562.0146" cy="272.7311" r="9.1571"/>
      <circle class="a" cx="398.1998" cy="376.2504" r="19.0793"/>
      <circle class="a" cx="565.3986" cy="359.0225" r="6.0432"/>
      <circle class="a" cx="210.9271" cy="370.0204" r="6.0432"/>
      <circle class="a" cx="387.7399" cy="446.1599" r="8.3014"/>
      <circle class="a" cx="430.8856" cy="555.2931" r="9.1571"/>
      <circle class="a" cx="478.2612" cy="584.0569" r="16.6469"/>
    </g>
    <path class="a" d="M274.0649,424.167l26.3842,18.3671a20.2407,20.2407,0,0,0,31.7325-14.903l2.7142-32.0329a20.2407,20.2407,0,0,0-28.7726-20.03l-29.0984,13.6659A20.2406,20.2406,0,0,0,274.0649,424.167Z"/>
    <path class="a" d="M439.0337,524.84l26.3842,18.367A20.2406,20.2406,0,0,0,497.15,528.3044l2.7142-32.0329a20.2406,20.2406,0,0,0-28.7726-20.03l-29.0984,13.6659A20.2406,20.2406,0,0,0,439.0337,524.84Z"/>
    <path class="a" d="M508.6356,256.9681l-70.386,26.1A20.2406,20.2406,0,0,0,432.37,317.63l57.7966,47.9059a20.2406,20.2406,0,0,0,32.8706-12.189L535.6269,279.34A20.2407,20.2407,0,0,0,508.6356,256.9681Z"/>
    <path class="a" d="M572.28,386.6822,380.2939,248.348c-26.201-18.8791-63.0138-2.315-66.2629,29.8154L290.2227,513.595c-3.2491,32.13,29.5022,55.729,58.9524,42.4777L564.969,458.9754C594.4192,445.7241,598.4808,405.5612,572.28,386.6822Zm-111.4245,46.97-7.4141,3.3361-.7382,8.712a17.1235,17.1235,0,0,1-26.8456,12.6078l-7.7729-5.4109L399.2783,461.36A24.9553,24.9553,0,0,1,364.21,436.0911l.9632-9.5245-15.17-10.56a17.1235,17.1235,0,0,1,2.5041-29.5528l17.5559-8.2451.94-9.298a24.9553,24.9553,0,0,1,39.4174-17.7361l12.43,8.9564,26.0564-12.2373a17.1235,17.1235,0,0,1,24.3415,16.945l-2.6352,31.1A24.9793,24.9793,0,0,1,460.8552,433.6527Z"/>
      </g>
    )
}

const A7 = () =>{
    return(
      <g>
      {/* <path d="M699,1V699H1V1H699m1-1H0V700H700V0Z"/> */}
      <path class="a" d="M611.1762,412.0237l-93.8809-35.0064a6.804,6.804,0,0,1-2.4763-11.1437l99.116-100.8822a2.508,2.508,0,0,0-2.8051-4.0507L480.6167,318.7772a6.8041,6.8041,0,0,1-9.5593-6.0826l-2.4133-118.948c-.1518-7.4781-10.5039-9.2716-13.1624-2.28l-32.1782,84.62a25.7223,25.7223,0,0,1-41.7678,9.4977l-50.9566-48.4545c-8.7148-8.2868-22.459,1.5672-17.4079,12.4808l40.9226,88.418a6.8041,6.8041,0,0,1-6.0807,9.6613l-120.1554,1.66c-7.5283.1041-9.3378,10.5482-2.2832,13.1787l112.594,41.984a6.8041,6.8041,0,0,1,2.4763,11.1438l-96.8513,98.5771a3.1246,3.1246,0,0,0,3.4949,5.0465l127.5587-56.5272a6.8041,6.8041,0,0,1,9.5593,6.0826l2.4134,118.948c.1517,7.4781,10.5038,9.2716,13.1623,2.28L442.2693,478.86a6.804,6.804,0,0,1,11.0483-2.5123l68.471,65.1089c9.3664,8.9065,24.1383-1.6844,18.71-13.4139L501.37,443.5015a6.804,6.804,0,0,1,6.0807-9.6613l100.1856-1.3842C619.3083,432.2947,622.1137,416.102,611.1762,412.0237Zm-153.2007,28.123-18.6635-16.2a5.7668,5.7668,0,0,0-9.0607,2.0371l-2.4278,5.531c-3.3522,7.6366-14.6935,5.6717-15.2813-2.6475l-.4257-6.0254a5.7669,5.7669,0,0,0-7.8474-4.9664l-9.4163,3.6714a6.9834,6.9834,0,0,1-7.81-11.0841l6.625-7.6324A5.7669,5.7669,0,0,0,391.63,393.77l-22.8368-10.0244a3.32,3.32,0,0,1,1.1006-6.3526l23.2908-1.6456a6.9388,6.9388,0,0,0,5.9757-9.4421l-4.883-12.5239a5.7668,5.7668,0,0,1,9.1531-6.45l11.3531,9.8546a5.7669,5.7669,0,0,0,9.0607-2.0372l6.0423-13.7651a5.7668,5.7668,0,0,1,11.033,1.9115l1.06,14.9955a5.7669,5.7669,0,0,0,7.8474,4.9665l4.134-1.6119c8.1555-3.18,15.1143,6.6956,9.3764,13.3061l-2.9086,3.3509a5.7668,5.7668,0,0,0,2.0371,9.0607l13.7651,6.0423a5.7668,5.7668,0,0,1-1.9115,11.033l-4.3617.3082A13.6177,13.6177,0,0,0,458.23,423.2768l5.1051,13.0935A3.3765,3.3765,0,0,1,457.9755,440.1467Z"/>
      <g>
        <g>
          <circle class="a" cx="571.2326" cy="463.1974" r="15.7448"/>
          <circle class="a" cx="283.4166" cy="317.176" r="15.7448"/>
          <circle class="a" cx="323.1011" cy="556.0893" r="15.7448"/>
          <circle class="a" cx="522.9977" cy="250.2049" r="15.7448"/>
        </g>
        <g>
          <circle class="a" cx="452.6885" cy="562.3019" r="15.7448"/>
          <circle class="a" cx="432.6885" cy="393.3019" r="20.3317"/>
          <circle class="a" cx="405.7702" cy="213.6569" r="15.7448"/>
          <circle class="a" cx="236.8447" cy="433.6817" r="15.7448"/>
          <circle class="a" cx="583.1087" cy="347.7849" r="15.7448"/>
        </g>
        <g>
          <circle class="a" cx="459.8369" cy="637.0249" r="8.3598"/>
          <circle class="a" cx="504.057" cy="576.276" r="5.3041"/>
          <circle class="a" cx="616.3212" cy="385.2993" r="9.7599"/>
          <circle class="a" cx="194.9552" cy="505.5863" r="8.6988"/>
          <circle class="a" cx="653.354" cy="340.6718" r="4.8476"/>
          <circle class="a" cx="609.354" cy="551.6718" r="4.8476"/>
          <circle class="a" cx="277.354" cy="584.6718" r="4.8476"/>
          <circle class="a" cx="219.5638" cy="278.7113" r="5.701"/>
        </g>
        <g>
          <circle class="a" cx="270.0079" cy="181.528" r="8.3598"/>
          <circle class="a" cx="447.9647" cy="144.3609" r="8.3598"/>
        </g>
      </g>
    </g>
    )
}

const A8 = () =>{
  return(
    <g>
    {/* <path d="M699,1V699H1V1H699m1-1H0V700H700V0Z"/> */}
    <path class="a" d="M453.7264,610.005l9.4366-141.4326a.7593.7593,0,0,1,1.1125-.6207l144.8877,76.59L501.9422,419.147a.7593.7593,0,0,1,.3451-1.2165l151.5671-48.647a.7593.7593,0,0,0-.1766-1.48l-130.3925-9.5477a19.6777,19.6777,0,0,1-16.1075-28.536l50.3027-99.0408a4.8583,4.8583,0,0,0-7.5489-5.84L446.2075,316.5037a.7594.7594,0,0,1-1.2273-.3416L396.5555,161.8637a.7593.7593,0,0,0-1.4821.1769L384.3072,323.4a.7593.7593,0,0,1-1.1125.6208l-144.8876-76.59L345.528,372.8259a.7594.7594,0,0,1-.345,1.2165l-151.5671,48.647a.7593.7593,0,0,0,.1766,1.48L352.55,435.7944a.7594.7594,0,0,1,.6216,1.1012L291.9244,557.485a5.6736,5.6736,0,0,0,8.8156,6.8205l100.5227-88.8363a.7594.7594,0,0,1,1.2273.3416l42.4445,135.2431A4.5043,4.5043,0,0,0,453.7264,610.005ZM379.4431,451.5591l19.0385-42.1146-38.0591-4.7183a1.9426,1.9426,0,0,1-.4468-3.7454l35.8809-13.5395-26.075-33.4554a.9387.9387,0,0,1,1.127-1.4324l38.6507,17.4727,5.6862-45.8666,15.8885,42.1058a.894.894,0,0,0,1.386.3895l35.4962-27.6656-19.0386,42.1145,45.8666,5.6863-43.2415,16.317,22.59,28.9836a2.3387,2.3387,0,0,1-2.808,3.5687L437.9,420.5222l-5.6863,45.8665L419.5271,432.768a7.5731,7.5731,0,0,0-11.7409-3.2995Z"/>
    <g>
      <g>
        <circle class="a" cx="395.4068" cy="554.2155" r="15.7448"/>
        <path class="a" d="M462.4449,245.0149C456,250,446,249,442,243c-9-16,3-41,20-42,13,0,12,20,9.4028,29.6267C469.7131,236.74,466.5938,241.8688,462.4449,245.0149Z"/>
        <circle class="a" cx="235.3792" cy="343.0538" r="15.7448"/>
        <path class="a" d="M572.9218,454.7547C567,448,571,437,578,433c14-7,32,3,36,16,4,15-18,16-28.6665,13.3224C579.9486,461.1589,575.5329,458.5546,572.9218,454.7547Z"/>
      </g>
      <g>
        <path class="a" d="M268.6251,482.0866c-4.02,1.5966-9.6855,3.1271-12.4091-1.9312-4.4764-8.3135,5.0707-30.52,21.633-31.8635,6.6879-.0857,12.5708,6.0592,11.7658,12.29-1.1951,8.5784-7.5928,12.4572-13.6778,17.2128A42.817,42.817,0,0,1,268.6251,482.0866Z"/>
        <circle class="a" cx="422.7227" cy="401.4619" r="20.3317"/>
        <path class="a" d="M560.9232,355.3507c-5.773-1.5424-11.2138-7.6633-7.8133-13.1041C559.2308,332.7253,579,330,581.674,336.1257c2.0427,4.68-4.0806,12.9219-10.159,17.195C567.8812,355.1719,564.1585,355.9633,560.9232,355.3507Z"/>
        <path class="a" d="M325.4618,255.9238C320.1852,249.0121,313.0883,226.2766,320,221s25.2145,8.9039,30.4911,15.8156a15.7448,15.7448,0,0,1-25.0293,19.1082Z"/>
        <path class="a" d="M495.75,531.5C497,527,499,523,503,521c10-4,22,3,25,13,2,8,2,18-5,23-10,5-19-3-24.5092-11.1088A21.78,21.78,0,0,1,495.75,531.5Z"/>
      </g>
      <g>
        <circle class="a" cx="196.9674" cy="497.2348" r="8.3598"/>
        <circle class="a" cx="267.8317" cy="522.2167" r="5.3041"/>
        <path class="a" d="M486.7361,600.1017a13.5691,13.5691,0,0,1-7.933-14.641c1.1677-5.8343,6.5164-8.9456,11.9264-6.991s8.9333,8.4493,7.8976,14.5668S492.2785,602.34,486.7361,600.1017Z"/>
        <circle class="a" cx="247.0903" cy="205.8139" r="8.6988"/>
        <path class="a" d="M532.5522,599.7717a4.6111,4.6111,0,0,1,5.9938-3.2971,5.4869,5.4869,0,0,1,3.4939,6.48,4.5871,4.5871,0,0,1-5.9954,3.2914A5.4868,5.4868,0,0,1,532.5522,599.7717Z"/>
        <circle class="a" cx="321.5454" cy="616.0659" r="4.8476"/>
        <circle class="a" cx="194.8984" cy="307.4017" r="4.8476"/>
        <circle class="a" cx="471.5169" cy="164.455" r="5.701"/>
      </g>
      <g>
        <circle class="a" cx="579.0727" cy="184.972" r="8.3598"/>
        <circle class="a" cx="643.6209" cy="413.845" r="8.3598"/>
      </g>
    </g>
    </g>
  )
}

const A9 = () =>{
  return(
    <g>
      <g>
        <path class="a" d="M398.4134,374.6806l-18.57-59.2486a2.2593,2.2593,0,0,1,.0082-1.3773l57.7418-176.7432a2.2592,2.2592,0,0,1,4.4065.7314l-2.456,185.92a2.2588,2.2588,0,0,1-.4371,1.3061l-36.7158,50.0719A2.2592,2.2592,0,0,1,398.4134,374.6806Z"/>
        <path class="a" d="M390.2949,457.9348l15.0373,47.9775a1.8289,1.8289,0,0,1-.0067,1.1152L358.5682,650.1481A1.8294,1.8294,0,0,1,355,649.5558l1.9888-150.5516a1.829,1.829,0,0,1,.3539-1.0576L387.0739,457.4A1.8294,1.8294,0,0,1,390.2949,457.9348Z"/>
        <path class="a" d="M448.2174,404.9183l47.4293-14.8655a1.8085,1.8085,0,0,1,1.1025.0066l141.4853,46.2231a1.8085,1.8085,0,0,1-.5855,3.5274l-148.8315-1.966a1.8088,1.8088,0,0,1-1.0455-.35l-40.0832-29.3916A1.8084,1.8084,0,0,1,448.2174,404.9183Z"/>
        <path class="a" d="M357.5924,396.5835,307.966,412.1376a1.8926,1.8926,0,0,1-1.1536-.0069l-148.04-48.3643a1.8923,1.8923,0,0,1,.6127-3.6909l155.726,2.0571a1.8923,1.8923,0,0,1,1.0939.3661l41.94,30.7531A1.8923,1.8923,0,0,1,357.5924,396.5835Z"/>
        <path class="a" d="M431.9979,376.9353l22.53-43.1009a2.26,2.26,0,0,1,.98-.968l129.8728-65.9106a2.2592,2.2592,0,0,1,2.5986,3.6331L483.6448,372.203a2.259,2.259,0,0,1-1.2326.6144l-48.0685,7.3974A2.2592,2.2592,0,0,1,431.9979,376.9353Z"/>
        <path class="a" d="M376.1862,418.2583l-22.4656,42.9769a2.2531,2.2531,0,0,1-.9769.9652L223.2444,527.9215a2.2527,2.2527,0,0,1-2.5912-3.6226L324.6878,422.977a2.2529,2.2529,0,0,1,1.2291-.6127l47.93-7.3761A2.2527,2.2527,0,0,1,376.1862,418.2583Z"/>
        <path class="a" d="M420.2891,428.8133l43.1009,22.53a2.2591,2.2591,0,0,1,.968.98l65.9106,129.8727a2.2592,2.2592,0,0,1-3.6331,2.5987L425.0215,480.46a2.2585,2.2585,0,0,1-.6145-1.2326L417.01,431.1591A2.2592,2.2592,0,0,1,420.2891,428.8133Z"/>
        <path class="a" d="M370.12,357.2265l-43.1009-22.53a2.2594,2.2594,0,0,1-.968-.98L260.14,203.8436a2.2592,2.2592,0,0,1,3.6331-2.5986L365.3875,305.58a2.2585,2.2585,0,0,1,.6145,1.2326l7.3974,48.0686A2.2592,2.2592,0,0,1,370.12,357.2265Z"/>
      </g>
      <g>
        <g>
          <path class="a" d="M372.4133,259.453l-13.5765-11.871a1.6552,1.6552,0,0,1-.5015-1.7021l8.7627-30.56a1.655,1.655,0,0,1,3.0392-.3448l15.386,27.8206a1.655,1.655,0,0,1-.1075,1.7712l-10.5721,14.6107A1.6551,1.6551,0,0,1,372.4133,259.453Z"/>
          <path class="a" d="M430.32,532.7874l25.4017,17.499a2.8308,2.8308,0,0,1,1.1868,2.7933l-8.8776,53.6464a2.8307,2.8307,0,0,1-5.0959,1.1835l-31.6129-44.2421a2.8309,2.8309,0,0,1-.1658-3.0305l15.0889-26.9032A2.8306,2.8306,0,0,1,430.32,532.7874Z"/>
          <path class="a" d="M526.9153,370.16l13.832-20.0784a2.2373,2.2373,0,0,1,2.2079-.9381l42.4041,7.0172a2.2375,2.2375,0,0,1,.9356,4.028l-34.9708,24.988a2.2372,2.2372,0,0,1-2.3953.131L527.6634,373.381A2.2375,2.2375,0,0,1,526.9153,370.16Z"/>
          <path class="a" d="M279.4371,425.1357,261.938,450.5373a2.8308,2.8308,0,0,1-2.7933,1.1868l-53.6464-8.8776a2.8307,2.8307,0,0,1-1.1835-5.0959l44.2422-31.6129a2.8307,2.8307,0,0,1,3.03-.1657l26.9033,15.0888A2.8309,2.8309,0,0,1,279.4371,425.1357Z"/>
        </g>
        <g>
          <path class="a" d="M469.3907,295.9507l-5.5879-30.3354a2.83,2.83,0,0,1,1.1359-2.8143L509.15,231.1447a2.8307,2.8307,0,0,1,4.44,2.7664l-8.93,53.6377a2.8307,2.8307,0,0,1-2.0256,2.26l-29.6929,8.354A2.8308,2.8308,0,0,1,469.3907,295.9507Z"/>
          <path class="a" d="M338.0183,505.0891l5.5879,30.3354a2.8306,2.8306,0,0,1-1.1359,2.8144l-44.2111,31.6563a2.8308,2.8308,0,0,1-4.44-2.7665l8.93-53.6376a2.8308,2.8308,0,0,1,2.0256-2.26l29.6929-8.354A2.8308,2.8308,0,0,1,338.0183,505.0891Z"/>
          <path class="a" d="M492.1862,460.8278l24.6437-4.54a2.3,2.3,0,0,1,2.2863.9228l25.7168,35.916a2.3,2.3,0,0,1-2.2474,3.6072L499.0118,489.48a2.3,2.3,0,0,1-1.836-1.6456l-6.7867-24.1218A2.3,2.3,0,0,1,492.1862,460.8278Z"/>
          <path class="a" d="M296.1353,337.8337,265.8,343.4216a2.83,2.83,0,0,1-2.8143-1.1359l-31.6563-44.2111a2.8307,2.8307,0,0,1,2.7664-4.44l53.6376,8.93a2.8308,2.8308,0,0,1,2.26,2.0256l8.354,29.693A2.8306,2.8306,0,0,1,296.1353,337.8337Z"/>
        </g>
      </g>
      <g>
        <g>
          <path class="a" d="M322.5491,154.0053l-8.68-5.98a2.9352,2.9352,0,0,1-1.2307-2.8964l3.0337-18.3324a2.9352,2.9352,0,0,1,5.284-1.2272l10.803,15.1187a2.9354,2.9354,0,0,1,.1719,3.1423l-5.1563,9.1936A2.9352,2.9352,0,0,1,322.5491,154.0053Z"/>
          <path class="a" d="M477.1,582.8713l6.8247,2.4187a3.3542,3.3542,0,0,1,2.2174,2.8311l1.257,12.7021a3.3541,3.3541,0,0,1-5.4669,2.9219l-9.8626-8.1025a3.3541,3.3541,0,0,1-1.1219-3.4166l1.7809-7.0182A3.354,3.354,0,0,1,477.1,582.8713Z"/>
          <path class="a" d="M506.6708,614.5227l3.8535,1.4058a1.9,1.9,0,0,1,1.2413,1.6153l.6459,7.202a1.9,1.9,0,0,1-3.1122,1.6268l-5.5447-4.6414a1.9,1.9,0,0,1-.6177-1.9413l1.0454-3.9664A1.9,1.9,0,0,1,506.6708,614.5227Z"/>
          <path class="a" d="M626.89,382.4888l6.8391-9.9278a4.6629,4.6629,0,0,1,4.6009-1.9549l20.9667,3.47a4.6626,4.6626,0,0,1,1.9495,8.3937l-17.2912,12.3553a4.6628,4.6628,0,0,1-4.9916.273l-10.5146-5.8972A4.6626,4.6626,0,0,1,626.89,382.4888Z"/>
          <path class="a" d="M609.4617,308.4213l2.8105-4.7887a2.1474,2.1474,0,0,1,2.0483-1.0515l9.7472.8942a2.1475,2.1475,0,0,1,1.1757,3.7908l-7.5309,6.2527a2.1475,2.1475,0,0,1-2.2839.2919l-5.0269-2.3582A2.1475,2.1475,0,0,1,609.4617,308.4213Z"/>
          <path class="a" d="M152.7755,422.5647l-8.0121,11.63a4.6624,4.6624,0,0,1-4.6009,1.9549L115.6,432.0852a4.6626,4.6626,0,0,1-1.95-8.3937l20.2565-14.4742a4.6628,4.6628,0,0,1,4.9916-.273l12.3178,6.9085A4.6627,4.6627,0,0,1,152.7755,422.5647Z"/>
          <path class="a" d="M178.8542,518.2836l-2.4242,6.149a2.182,2.182,0,0,1-1.8912,1.3773l-11.628.7417a2.1822,2.1822,0,0,1-1.7756-3.621l7.7064-8.7391a2.182,2.182,0,0,1,2.2469-.6518l6.3459,1.8485A2.1821,2.1821,0,0,1,178.8542,518.2836Z"/>
        </g>
        <g>
          <path class="a" d="M545.5985,215.2521l-2.9353-15.935a2.73,2.73,0,0,1,1.0955-2.7143l23.2239-16.6289a2.73,2.73,0,0,1,4.2823,2.6681l-4.6911,28.1755a2.73,2.73,0,0,1-1.9535,2.18l-15.5976,4.3883A2.73,2.73,0,0,1,545.5985,215.2521Z"/>
          <path class="a" d="M460.578,224.2181l-1.8553-5.7419a1.0164,1.0164,0,0,1,.2731-1.0552l7.77-7.2645a1.0167,1.0167,0,0,1,1.71.7778l-.3681,10.6309a1.0167,1.0167,0,0,1-.6158.8994l-5.5469,2.3755A1.0167,1.0167,0,0,1,460.578,224.2181Z"/>
          <path class="a" d="M250.4155,576.5078l2.4887,13.51a4.6627,4.6627,0,0,1-1.8711,4.6357l-19.69,14.0983a4.6627,4.6627,0,0,1-7.3138-4.5568l3.9772-23.8877a4.6627,4.6627,0,0,1,3.3365-3.7227l13.2239-3.7205A4.6626,4.6626,0,0,1,250.4155,576.5078Z"/>
          <path class="a" d="M608.0981,524.99l9.9145-1.8263a4.6626,4.6626,0,0,1,4.6357,1.871l10.3462,14.4495a4.6627,4.6627,0,0,1-4.5568,7.3138l-17.53-2.9187a4.6626,4.6626,0,0,1-3.7226-3.3365l-2.73-9.7045A4.6627,4.6627,0,0,1,608.0981,524.99Z"/>
          <path class="a" d="M218.6245,247.4109l-6.027,1.11a2.8033,2.8033,0,0,1-2.7873-1.125l-6.2894-8.7838a2.8035,2.8035,0,0,1,2.74-4.3975l10.6567,1.7742a2.8037,2.8037,0,0,1,2.2383,2.0062l1.66,5.8993A2.8035,2.8035,0,0,1,218.6245,247.4109Z"/>
        </g>
      </g>
    </g>
  )
}

const B1 = () =>{
    return(
        <g>
        {/* <path d="M699,1V699H1V1H699m1-1H0V700H700V0Z"/> */}
    <rect class="a" x="136.0807" y="346.7953" width="196" height="196" transform="translate(-243.5345 601.3853) rotate(-80.3958)"/>
    <rect class="a" x="218.0266" y="255.6056" width="63.138" height="63.138" transform="translate(102.071 637.8935) rotate(-116.1914)"/>
    <rect class="a" x="356.5881" y="471.6146" width="91.2971" height="91.2971" transform="translate(-30.4816 1009.3103) rotate(-100.8013)"/>
      </g>
    )
}

const B2 = () =>{
    return(
        <g>
        <rect class="a" x="137" y="370" width="204" height="204" rx="49.8158" transform="translate(-181.8213 792.6469) rotate(-100.4501)"/>
    <rect class="a" x="371.9257" y="447.9257" width="74.1487" height="74.1487" rx="20.848" transform="translate(-165.4235 754.3393) rotate(-74.9803)"/>
    <rect class="a" x="221.4034" y="223.4034" width="115.1932" height="115.1932" rx="20.848" transform="translate(-61.3481 483.3985) rotate(-75.9438)"/>
      </g>
    )
}

const B3 = () =>{
    return(
        <g>
    <rect class="a" x="143" y="356" width="196" height="196" rx="82.9999" transform="translate(-152.1697 785.0809) rotate(-102.1391)"/>
    <rect class="a" x="340.2446" y="499.2446" width="109.5107" height="109.5107" rx="37.7922" transform="translate(-282.8401 612.9387) rotate(-59.4807)"/>
    <rect class="a" x="333.5682" y="287.5682" width="58.8636" height="58.8636" rx="19.4476" transform="translate(290.7361 793.4829) rotate(-122.5062)"/>
      </g>
    )
}

const B4 = () =>{
    return(
        <g>
    <circle class="a" cx="247.7424" cy="500.6858" r="68.7569"/>
    <g>
      <circle class="a" cx="260.6746" cy="608.0974" r="27.2897"/>
      <circle class="a" cx="164.3711" cy="535.1169" r="14.6789"/>
      <circle class="a" cx="280.7516" cy="374.6917" r="14.6789"/>
      <circle class="a" cx="343.5005" cy="441.0323" r="27.2897"/>
      <circle class="a" cx="157.3986" cy="413.7536" r="39.3879"/>
      <circle class="a" cx="376.5683" cy="567.8582" r="47.0679"/>
    </g>
      </g>
    )
}

const B5 = () =>{
    return(
        <g>
    {/* <path d="M699,1V699H1V1H699m1-1H0V700H700V0Z"/> */}
    <circle class="a" cx="246.75" cy="483.6667" r="56.1652"/>
    <g>
      <circle class="a" cx="268.75" cy="573.0413" r="24.8146"/>
      <circle class="a" cx="159.042" cy="573.0573" r="11.9907"/>
      <circle class="a" cx="294.8993" cy="644.0371" r="11.9907"/>
      <circle class="a" cx="425.8452" cy="427.1959" r="11.9907"/>
      <circle class="a" cx="183.042" cy="357.0573" r="11.9907"/>
      <circle class="a" cx="342.8993" cy="333.2948" r="11.9907"/>
      <circle class="a" cx="236.75" cy="384.2921" r="22.2921"/>
      <circle class="a" cx="135.3754" cy="461.6667" r="32.1746"/>
      <circle class="a" cx="342.998" cy="532.0026" r="38.4482"/>
        </g>
      </g>
    )
}

const B6 = () =>{
    return(
        <g>
    {/* <path d="M699,1V699H1V1H699m1-1H0V700H700V0Z"/> */}
    <circle class="a" cx="246.6879" cy="483.7592" r="44.1447"/>
    <g>
      <circle class="a" cx="246.6879" cy="566.15" r="20.5501"/>
      <circle class="a" cx="246.6879" cy="401.3685" r="20.5501"/>
      <circle class="a" cx="164.2972" cy="483.7592" r="20.5501"/>
      <circle class="a" cx="329.0787" cy="483.7592" r="20.5501"/>
    </g>
    <g>
      <circle class="a" cx="188.4289" cy="542.0183" r="20.5501"/>
      <circle class="a" cx="304.947" cy="425.5002" r="20.5501"/>
      <circle class="a" cx="188.4289" cy="425.5002" r="20.5501"/>
      <circle class="a" cx="304.947" cy="542.0183" r="20.5501"/>
    </g>
    <g>
      <g>
        <circle class="a" cx="369.5414" cy="533.6937" r="10.6574"/>
        <circle class="a" cx="124.835" cy="432.333" r="10.6574"/>
        <circle class="a" cx="196.5078" cy="605.3665" r="10.6574"/>
        <circle class="a" cx="297.8685" cy="360.6602" r="10.6574"/>
        <circle class="a" cx="391.8685" cy="365.6602" r="6.1718"/>
        <circle class="a" cx="442.8685" cy="480.6602" r="3.4306"/>
        <circle class="a" cx="364.8685" cy="594.6602" r="6.1718"/>
        <circle class="a" cx="273.8685" cy="638.6602" r="6.1718"/>
        <circle class="a" cx="336.8685" cy="662.6602" r="3.4279"/>
        <circle class="a" cx="143.8685" cy="615.6602" r="6.1718"/>
        <circle class="a" cx="75.8685" cy="483.6602" r="6.1718"/>
        <circle class="a" cx="143.8685" cy="305.6602" r="6.1718"/>
      </g>
      <g>
        <circle class="a" cx="297.8685" cy="605.3665" r="10.6574"/>
        <circle class="a" cx="196.5078" cy="360.6602" r="10.6574"/>
        <circle class="a" cx="124.835" cy="533.6937" r="10.6574"/>
        <circle class="a" cx="369.5414" cy="432.333" r="10.6574"/>
      </g>
    </g>
  </g>
    )
}

const B7 = () =>{
    return(
        <g>
    {/* <path d="M699,1V699H1V1H699m1-1H0V700H700V0Z"/> */}
    <path class="a" d="M182.4591,448.29c24.4168,8.0128,55.1842,16.1148,65.4619,42.7231,11.045,28.595-10.9831,56.324-39.7674,59.7107C135.49,559.2733,126.6207,431.3973,182.4591,448.29Z"/>
    <g>
      <path class="a" d="M211.2187,553.4524c25.7926-3.18,41.947,27.2334,12.0293,34.8436C195.7637,595.2872,179.7938,557.946,211.2187,553.4524Z"/>
      <path class="a" d="M176.25,392.6129c20.1438,10.0941,32.5533,57.4112-1.1783,48.4876C149.0249,434.21,148.9188,378.7533,176.25,392.6129Z"/>
      <path class="a" d="M137.7,495.5619c1.1169,31.4363-25.4468,24.9011-29.3637-1.6049C103.2321,459.4154,133.4905,465.6549,137.7,495.5619Z"/>
      <path class="a" d="M308.2756,505.1338c6.5678,22.3426-35.2181,16.6582-38.9162-2.9541C264.4472,476.129,304.7217,486.61,308.2756,505.1338Z"/>
    </g>
    <g>
      <path class="a" d="M159.0481,539.52c22.41,17.666.4648,48.4464-20.5551,25.7817C121.8207,547.3245,136.8406,516.5611,159.0481,539.52Z"/>
      <path class="a" d="M260.6044,437.4446c24.9971,25.7372-10.582,43.052-30.8808,21.4018C205.6,433.1173,240.3214,419.8712,260.6044,437.4446Z"/>
      <path class="a" d="M145.5142,452.3291c-5.0891,22.0054-49.686-23.4195-20.4062-44.1931C149.0857,391.1242,154.7557,438.318,145.5142,452.3291Z"/>
      <path class="a" d="M290.4256,564.6129c-13.0781,16.3745-47.873-.7955-36.2319-21.4242C266.9931,520.5076,305.7092,543.7065,290.4256,564.6129Z"/>
    </g>
    <g>
      <g>
        <path class="a" d="M336.7925,542.7209c5.3153,9.8562-7.4247,16.8337-14.3222,8.7544C313.617,541.1053,330.9112,530.3727,336.7925,542.7209Z"/>
        <path class="a" d="M92.9255,420.5981c1.4076,8.1375-1.7028,24.75-12.0127,14.6608S89.4865,401.815,92.9255,420.5981Z"/>
        <path class="a" d="M173.6708,629.6944c-11.0663,2.9672-22.897-11.3312-9.516-16.7078C174.0744,609.0007,186.84,624.6674,173.6708,629.6944Z"/>
        <path class="a" d="M243.99,390.29c-13.24,1.4953-20.67-16.2631-8.6625-23.1844C251.2939,357.9027,260.65,387.3019,243.99,390.29Z"/>
        <path class="a" d="M97.99,331.29c-13.24,1.4953-20.67-16.2631-8.6625-23.1844C105.2939,298.9027,114.65,328.3019,97.99,331.29Z"/>
        <path class="a" d="M310.0157,453.1436c-7.5338,4.1368-10.9276-10.114-2.8239-12.7837C316.2439,437.3777,316.4921,449.3385,310.0157,453.1436Z"/>
        <path class="a" d="M465.08,611.1162c-3.08-.1162-7.6213,1.053-6.3507-3.0316S475.88,606.1192,465.08,611.1162Z"/>
        <path class="a" d="M368.4524,598.6921c-4.9415,1.19-15.1482.9888-17.3911-5.346C348.9149,587.2834,373.4708,595.7405,368.4524,598.6921Z"/>
        <path class="a" d="M270.7217,645.9768c-7.18,3.1124-18.9414-3.55-9.05-9.5876C268.6368,632.1382,282.318,642.0485,270.7217,645.9768Z"/>
        <path class="a" d="M382.986,650.4943c-1.365,1.0028-10.8666.2615-8.4263-2.1164s3.14-6.0063,11.3548-2.8326Q387.3065,649.2367,382.986,650.4943Z"/>
        <path class="a" d="M470.986,628.4943c-1.365,1.0028-7.8666,1.2615-8.4263-2.1164s3.14-6.0063,11.3548-2.8326Q475.3065,627.2367,470.986,628.4943Z"/>
        <path class="a" d="M494.986,616.4943c-1.365,1.0028-8.8666.2615-8.4263-2.1164s3.14-6.0063,11.3548-2.8326Q499.3065,615.2367,494.986,616.4943Z"/>
        <path class="a" d="M114.2374,604.1959c-13.36,1.7391-8.1813-13.7259-3.2093-10.4609S124.3249,601.8047,114.2374,604.1959Z"/>
        <path class="a" d="M51.9755,488.7046c-6.5825,3.1652-11.0145-9.0225-4.681-11.6622C53.9851,474.2539,58.0175,485.7993,51.9755,488.7046Z"/>
        <path class="a" d="M129.2025,278.2615c-12.883,3.6775-3.9179-8.1085-.56-9.185S140.1633,274.5393,129.2025,278.2615Z"/>
      </g>
      <g>
        <path class="a" d="M303.4735,610.9226c.7358,11.5833-32.0987,6.51-29.5955-5.7614C276.5516,592.0546,302.7843,600.0727,303.4735,610.9226Z"/>
        <path class="a" d="M422.4735,628.9226c.7358,11.5833-32.0987,6.51-29.5955-5.7614C395.5516,610.0546,421.7843,618.0727,422.4735,628.9226Z"/>
        <path class="a" d="M153.1471,343.9822c-8.6072,12.0027-18.7032-7.9479-10.4126-15.822C153.6844,317.76,159.2914,335.4139,153.1471,343.9822Z"/>
        <path class="a" d="M92.3707,551.6458c-8.1894-6.1988-9.3868-25.78,4.989-19.5769C107.0966,536.2705,105.0015,561.2064,92.3707,551.6458Z"/>
        <path class="a" d="M314.5339,474.7692c-9.4767-.6529-9.9434-18.3152,2.2585-16.4436C327.42,459.9557,328.6472,480.3568,314.5339,474.7692Z"/>
      </g>
    </g>
  </g>
    )
}

const C1 = () =>{
    return(
        <g>
        <path class="a" d="M174.2585,239.887c-1.8654,11.1779-10.6519,25.3714-23.98,20.2152-16.972-6.5657-16.3051-31.5766-14.323-48.66,4.681-40.3457,38.1148-76.6646,80.1626-77.4969,21.6453-.4285,54.278,6.275,65.1315,26.6848,12.6266,23.7442-2.1046,34.1465-26.1,34.6035-16.3628.3117-33.112-1.1385-48.9245,4.2127C185.7836,206.3638,176.1239,228.7091,174.2585,239.887Z"/>
    <path class="a" d="M223.4672,301.6584c-7.639-1.2268-15.8447-2.64-22.7889-6.9628-11.5849-6.8117-15.7864-24.6028-3.2076-31.8991,19.7763-11.8018,52.5-23.232,67.16-.4285,10.7261,16.2114-8.6476,34.5039-25.475,37.7959A51.2188,51.2188,0,0,1,223.4672,301.6584Z"/>
    <path class="a" d="M307.0581,223.4047c1.8631,9.4114-4.22,22.2531-15.034,19.55-8.7864-2.0276-15.5453-11.49-14.1935-20.9523,1.3518-6.083,6.0829-10.8141,12.1659-11.49s10.8141,2.7035,14.5374,7.0345A15.1185,15.1185,0,0,1,307.0581,223.4047Z"/>
      </g>
    )
}

const C2 = () =>{
    return(
        <g>
        <path class="a" d="M222.7085,285.5459a21.0105,21.0105,0,0,1-11.7778,4.52,32.1269,32.1269,0,0,1-12.9669-1.9271,44.6225,44.6225,0,0,1-11.7825-6.57,50.2093,50.2093,0,0,1-9.5277-9.6209c-18.4816-25.59-16.3492-57.5773-2.8434-83.8781a59.6856,59.6856,0,0,1,23.51-24.1419,66.14,66.14,0,0,1,15.592-6.411c9-2.8519,18.318-2.8547,27.3214-1.276a92.2128,92.2128,0,0,1,13.19,3.3957c21.3249,8.53,41.2282,34.8307,31.9874,56.1556-8.53,20.6141-30.5657,31.2766-43.9251,49.052q-3.1844,3.9219-6.3439,7.9007-3.1606,3.9811-6.303,8.0336A48.4053,48.4053,0,0,1,222.7085,285.5459Z"/>
    <path class="a" d="M277.9993,249.0329a8.2037,8.2037,0,0,1,4.5809-1.8117,12.5473,12.5473,0,0,1,5.071.7009,17.426,17.426,0,0,1,4.6268,2.5187,19.6031,19.6031,0,0,1,3.7586,3.7189c7.3183,9.9188,6.6127,22.4176,1.4435,32.741a23.3075,23.3075,0,0,1-9.0842,9.52,25.83,25.83,0,0,1-6.0628,2.5652,22.3235,22.3235,0,0,1-10.6633.6068,36.0059,36.0059,0,0,1-5.1637-1.2735c-8.3608-3.246-16.237-13.4368-12.7134-21.8,3.2488-8.0832,11.8109-12.3342,16.9568-19.3282q1.2277-1.5439,2.4457-3.11t2.4293-3.162A18.8989,18.8989,0,0,1,277.9993,249.0329Z"/>
      </g>
    )
}

const C3 = () =>{
    return(
        <g>
        <rect class="a" x="165.3828" y="157.8462" width="112.5545" height="112.5545" rx="56.2772"/>
    <rect class="a" x="249.901" y="270.1468" width="56.0726" height="56.0726" rx="28.0363"/>
    <rect class="a" x="263.7485" y="147.2192" width="21.2539" height="21.2539" rx="10.627"/>
      </g>
    )
}

const C4 = () =>{
    return(
        <g>
        <path class="a" d="M228.6812,262.8525l-25.3588,2.9769-38.4448-30.3669-2.9769-25.3589,30.3669-38.4448,25.3588-2.9769,38.4448,30.367,2.9769,25.3588Z"/>
    <rect class="a" x="226.2008" y="270.789" width="34.559" height="34.559" rx="7.8525" transform="translate(46.361 -33.5059) rotate(8.6975)"/>
    <rect class="a" x="135.5239" y="223.3198" width="19.0773" height="19.0773" rx="0.0152" transform="translate(-114.4565 143.8863) rotate(-39.1551)"/>
    <rect class="a" x="258.0004" y="165.7637" width="11.7674" height="11.7674" transform="translate(351.6659 -130.6637) rotate(73.1491)"/>
      </g>
    )
}

const C5 = () =>{
    return(
        <g>
        <path class="a" d="M231.4236,272.7336,168.809,248.6559l-12.9578-29.15,24.0777-62.6145,29.15-12.9579,62.6146,24.0777,12.9578,29.15-24.0777,62.6146Z"/>
    <path class="a" d="M154.3213,131.0887l-5.9053,8.2761a6.5711,6.5711,0,0,0,4.7181,10.3574l10.12.9761a6.571,6.571,0,0,0,6.6107-9.2647l-4.2146-9.2522A6.5711,6.5711,0,0,0,154.3213,131.0887Z"/>
    <path class="a" d="M311.5786,200.75l-11.6131,5.89a10.1025,10.1025,0,0,0-.948,17.4724l10.9075,7.1121a10.1025,10.1025,0,0,0,15.6056-7.9152l.7055-13.0022A10.1025,10.1025,0,0,0,311.5786,200.75Z"/>
    <path class="a" d="M148.2189,305.5732l29.2631-5.09a10.1025,10.1025,0,0,0,6.0228-16.4289l-19.0395-22.7976a10.1025,10.1025,0,0,0-17.2392,2.9985l-10.2236,27.8875A10.1025,10.1025,0,0,0,148.2189,305.5732Z"/>
      </g>
    )
}

const C6 = () =>{
    return(
        <g>
    <rect class="a" x="176.3716" y="174.0233" width="98.9482" height="98.9482" transform="translate(-90.912 248.436) rotate(-49.2022)"/>
    <polygon class="a" points="183.253 337.399 191.01 276.915 134.751 300.439 183.253 337.399"/>
    <polygon class="a" points="300.906 144.759 266.756 140.521 280.161 172.216 300.906 144.759"/>
    <polygon class="a" points="107.214 208.697 132.716 201.864 114.047 183.195 107.214 208.697"/>
  </g>
    )
}

const C7 = () =>{
    return(
        <g>
    <rect class="a" x="191.4456" y="152.6761" width="80.4822" height="80.4822" transform="translate(191.7862 -105.9201) rotate(42.612)"/>
    <rect class="a" x="161.6417" y="243.8763" width="46.0307" height="46.0307" transform="translate(-85.8973 87.644) rotate(-21.7961)"/>
    <rect class="a" x="290.6661" y="217.6473" width="18.3722" height="18.3722" transform="translate(-16.6926 23.9439) rotate(-4.4491)"/>
  </g>
    )
}

const D1 = () =>{
    return(
        <g>
         <path class="a" d="M123.1191,528.2962C99,539,87,566,85,591c-1,18,6,37,23,46,16,8,34,6,49-5,18-14,26-35,24-57s-15-46-39.3908-49.2393A36.1579,36.1579,0,0,0,123.1191,528.2962Zm29.3167,74.0194c-3.4058,1.6276-7.931.8734-11.702-.635-14.33-9.05-12.0672-29.4139-2.2626-40.7269,4.5252-4.5252,11.313-7.542,18.1008-4.5252,12.0673,6.7878,13.5757,23.38,8.0566,34.7618C161.9165,596.5334,157.5882,600.6477,152.4358,602.3156Z"/>
    <path class="a" d="M104.9881,344.5522C96,344,89,350,86,356c-13,23,7,48,28,57,12,4,26-1,31-13,7-18-3-36-16.3087-47.6176C121.6775,346.94,113.29,343.9415,104.9881,344.5522Zm20.1422,39.349c-6.4693.3633-13.7505-5.4781-15.996-12.7491s1.28-13.3243,7.6211-13.7772,13.2109,4.964,15.5875,12.3319S131.6,383.538,125.13,383.9012Z"/>
    <path class="a" d="M623.9506,481.3751c-13.3029-1.37-24.02,12.4734-20.8945,25.4239,3.5725,16.0764,21.8818,25.9009,36.1719,16.97,5.3588-3.1259,8.0382-9.3779,8.0382-16.0764,0-9.8245-6.2519-18.7558-14.7681-23.7968A21.3316,21.3316,0,0,0,623.9506,481.3751Zm-3.0437,37.1208a7.98,7.98,0,0,1-6.98-9.4421c1.316-5.0008,7.6329-5.5272,11.0545-2.1056,2.632,2.632,3.4216,7.6329.6533,10.1465A6.13,6.13,0,0,1,620.9069,518.4959Z"/>
        </g>
    )
}

const D2 = () =>{
    return(
            <g>
            <path class="a" d="M572.84,417.0889C544,419,530,453,533,479c3,20,19,35,38,37,20,3,39-8,48-26,12-26-1-60-28.2643-70.5851A39.8336,39.8336,0,0,0,572.84,417.0889ZM580.6367,494.2C572,495,563,491,559,483c-8-15-1-37,17-40,13-2,22,9,26,20a25.9708,25.9708,0,0,1-21.3633,31.2Z"/>
    <path class="a" d="M114.808,212.3734C80,214,56,250,61,284c3,18,16,34,34,36,39,4,73-35,64-73-4-14-12-26-25.672-31.0525A43.3283,43.3283,0,0,0,114.808,212.3734Zm-9.9967,78.7c-8.9567.8-15.81-6.815-16.5716-15.1914-.7614-10.6608,5.33-21.3216,14.4683-26.652,6.0918-3.0459,13.7067-3.0459,19.0371,1.523,9.8993,9.1378,7.6148,24.3675-2.0484,33.0512C115.5677,287.9874,110.2687,290.75,104.8113,291.0731Z"/>
    <path class="a" d="M163.25,202.7175A12.4675,12.4675,0,1,1,175.7175,190.25,12.4675,12.4675,0,0,1,163.25,202.7175Z"/>
    <path class="a" d="M617.7445,184.9556a14.6619,14.6619,0,0,1-12.6765-14.5937,11.6865,11.6865,0,0,1,13.02-11.83c7.09.7487,12.7624,7.2836,12.6733,14.6S624.8444,185.7376,617.7445,184.9556Z"/>
    <path class="a" d="M128.25,606.7175A12.4675,12.4675,0,1,1,140.7175,594.25,12.4675,12.4675,0,0,1,128.25,606.7175Z"/>
    <path class="a" d="M516.6048,430.4929C502,430,492,414,492,400c1-9,5-17,14-20,11-3,22,2,27,13,4,10,5,21-1.9834,30.1815A16.329,16.329,0,0,1,516.6048,430.4929Z"/>
    <path class="a" d="M468.8888,550.653C457,550,447,545,440,534c-6-10-6-23,2-32a36.4447,36.4447,0,0,1,15.3923-9.1746C472,488,488,494,499,505c11,12,9,32-4.8828,39.08A40.8515,40.8515,0,0,1,468.8888,550.653Z"/>
            </g>
    )
}

const D3 = () =>{
    return(
            <g>
            <path class="a" d="M601.25,265a47.25,47.25,0,1,0,47.25,47.25A47.25,47.25,0,0,0,601.25,265Zm0,71.5717A24.3217,24.3217,0,1,1,625.5717,312.25,24.3217,24.3217,0,0,1,601.25,336.5717Z"/>
    <path class="a" d="M105.25,328a47.25,47.25,0,1,0,47.25,47.25A47.25,47.25,0,0,0,105.25,328Zm0,71.5717A24.3217,24.3217,0,1,1,129.5717,375.25,24.3217,24.3217,0,0,1,105.25,399.5717Z"/>
    <path class="a" d="M173.25,334.2429a9.0071,9.0071,0,1,0,9.0071,9.0071A9.0071,9.0071,0,0,0,173.25,334.2429Z"/>
    <path class="a" d="M563.25,80.2429a9.0071,9.0071,0,1,0,9.0071,9.0071A9.0071,9.0071,0,0,0,563.25,80.2429Z"/>
    <path class="a" d="M675.25,330.4217a4.8283,4.8283,0,1,0,4.8283,4.8283A4.8283,4.8283,0,0,0,675.25,330.4217Z"/>
    <path class="a" d="M56.25,269.8605a23.39,23.39,0,1,0,23.39,23.39A23.39,23.39,0,0,0,56.25,269.8605Z"/>
    <path class="a" d="M237.25,433.8605a23.39,23.39,0,1,0,23.39,23.39A23.39,23.39,0,0,0,237.25,433.8605Z"/>
    <path class="a" d="M541.25,360.3908A13.8592,13.8592,0,1,0,555.1092,374.25,13.8592,13.8592,0,0,0,541.25,360.3908Z"/>
    <path class="a" d="M591.25,586.3908A13.8592,13.8592,0,1,0,605.1092,600.25,13.8592,13.8592,0,0,0,591.25,586.3908Z"/>
            </g>
    )
}

const D4 = () =>{
    return(
            <g>
            {/* <path d="M699,1V699H1V1H699m1-1H0V700H700V0Z"/> */}
    <path class="a" d="M118.881,622.01l39.0469-24.4487a18.24,18.24,0,0,0,5.78-25.14l-24.4488-39.047a18.24,18.24,0,0,0-25.14-5.78L75.0721,552.0428a18.24,18.24,0,0,0-5.78,25.14L93.741,616.23A18.24,18.24,0,0,0,118.881,622.01Zm11.3585-62.9866,6.4555,10.31a13.9367,13.9367,0,0,1-4.4161,19.2084l-10.31,6.4555a13.9366,13.9366,0,0,1-19.2083-4.4162l-6.4555-10.31a13.9367,13.9367,0,0,1,4.4161-19.2084l10.31-6.4555A13.9365,13.9365,0,0,1,130.2395,559.023Z"/>
    <path class="a" d="M406.8072,158.4322l20.7973-21.9948a11.985,11.985,0,0,0-.4742-16.9428L405.1355,98.6974a11.985,11.985,0,0,0-16.9427.4741l-20.7973,21.9948a11.985,11.985,0,0,0,.4742,16.9428l21.9948,20.7972A11.985,11.985,0,0,0,406.8072,158.4322Zm-3.1738-41.9336,5.8075,5.4914a9.1572,9.1572,0,0,1,.3623,12.9452l-5.4913,5.8076a9.1574,9.1574,0,0,1-12.9453.3622l-5.8075-5.4913a9.1572,9.1572,0,0,1-.3623-12.9452l5.4913-5.8076A9.1575,9.1575,0,0,1,403.6334,116.4986Z"/>
    <path class="a" d="M591.1332,539.0472l-18.8544-10.2753a8.5016,8.5016,0,0,0-11.5334,3.3967L550.47,551.023a8.5017,8.5017,0,0,0,3.3968,11.5335l18.8544,10.2753a8.5017,8.5017,0,0,0,11.5334-3.3967L594.53,550.5806A8.5017,8.5017,0,0,0,591.1332,539.0472Zm-28.1827,9.7788,2.7131-4.9784a6.4959,6.4959,0,0,1,8.8122-2.5953l4.9784,2.7132a6.4958,6.4958,0,0,1,2.5953,8.8122l-2.7131,4.9783a6.4957,6.4957,0,0,1-8.8122,2.5953l-4.9784-2.7131A6.4958,6.4958,0,0,1,562.9505,548.826Z"/>
    <path class="a" d="M528.4044,274.5624l51.0226,31.9045a23.8257,23.8257,0,0,0,32.8335-7.57l31.9046-51.0225a23.8259,23.8259,0,0,0-7.57-32.8336L585.573,183.1368a23.8258,23.8258,0,0,0-32.8335,7.5694l-31.9046,51.0226A23.8259,23.8259,0,0,0,528.4044,274.5624Zm80.4788-22.633-8.4241,13.4722a18.2044,18.2044,0,0,1-25.0867,5.7835L561.9,262.7609a18.2043,18.2043,0,0,1-5.7835-25.0867l8.4241-13.4721a18.2044,18.2044,0,0,1,25.0867-5.7835L603.1,226.8428A18.2042,18.2042,0,0,1,608.8832,251.9294Z"/>
            </g>
    )
}

const D5 = () =>{
    return(
            <g>
                <path class="a" d="M108.0345,298.8322l21.7977,51.1333,51.1333-21.7977-21.7977-51.1333Zm52.1221,20.9655-21.9543,9.3589-9.3589-21.9543,21.9543-9.3589Z"/>
    <path class="a" d="M71.1482,435.2692l31.121,3.5826,3.5826-31.121-31.121-3.5826ZM95.95,415.5882,94.4118,428.95,81.05,427.4118,82.5882,414.05Z"/>
    <path class="a" d="M603.7915,205.4034l-35.3881,42.8051,42.8051,35.3881,35.3881-42.8051Zm5.3008,55.8828-18.3785-15.1939,15.1939-18.3785,18.3785,15.1939Z"/>
    <path class="a" d="M565.57,106.0367,580.0367,131.43,605.43,116.9633,590.9633,91.57Zm28.4869,7.809-10.9027,6.2113-6.2113-10.9027,10.9027-6.2113Z"/>
            </g>
    )
}

const D6 = () =>{
    return(
        <g>
        <path class="a" d="M146.3566,546.99,91.9349,612.7832a2.1208,2.1208,0,0,0,1.348,3.4531l84.6029,11.5245a2.1208,2.1208,0,0,0,2.2618-2.8726l-30.1812-77.3177A2.1208,2.1208,0,0,0,146.3566,546.99Zm-2.3243,36.1629,7.6443,19.5831a2.1207,2.1207,0,0,1-2.2037,2.88l-22.6335-2.4484a2.1207,2.1207,0,0,1-1.3681-3.5048l14.9892-17.1347A2.1208,2.1208,0,0,1,144.0323,583.1529Z"/>
    <path class="a" d="M636.8006,310.4663l-24.9977-21.897a2.1208,2.1208,0,0,0-3.49,1.25l-5.415,32.7879a2.1209,2.1209,0,0,0,2.8075,2.3422l30.4127-10.8908A2.1209,2.1209,0,0,0,636.8006,310.4663Zm-16.13.5317-4.9714,1.78a2.1208,2.1208,0,0,1-2.8162-2.2843l.7756-5.6657a2.1208,2.1208,0,0,1,3.5421-1.2685l4.1959,3.8855A2.1208,2.1208,0,0,1,620.67,310.998Z"/>
    <path class="a" d="M334.3245,570.9034l-7.1828,14.0023a2.1208,2.1208,0,0,0,2.06,3.0817l15.6846-1.2847a2.1208,2.1208,0,0,0,1.59-3.2924l-8.5018-12.7176A2.1208,2.1208,0,0,0,334.3245,570.9034Zm4.07,8.709h0a2.1208,2.1208,0,0,1-1.5317,3.2868h0a2.1208,2.1208,0,0,1-2.091-3.1279h0A2.1209,2.1209,0,0,1,338.3948,579.6124Z"/>
    <path class="a" d="M671.6716,439.4164l-82.18,23.1719a2.1208,2.1208,0,0,0-.8549,3.6069l63.0382,57.59a2.1208,2.1208,0,0,0,3.4941-1.0767l19.1417-80.7621A2.1209,2.1209,0,0,0,671.6716,439.4164Zm-23.533,27.39-5.2,21.9409a2.1208,2.1208,0,0,1-3.45,1.1156L621.0118,473.897a2.1208,2.1208,0,0,1,.8677-3.661l23.6765-5.9751A2.1209,2.1209,0,0,1,648.1386,466.8064Z"/>
    <path class="a" d="M69.22,310.4373l3.1056,46.1175a2.1207,2.1207,0,0,0,3.3533,1.58l37.54-26.967a2.1208,2.1208,0,0,0-.3334-3.641L72.24,308.3763A2.1208,2.1208,0,0,0,69.22,310.4373Zm13.92,15.6835,9.1221,4.2979a2.1208,2.1208,0,0,1,.38,3.6061l-8.69,6.6137a2.1208,2.1208,0,0,1-3.4036-1.6036l-.4324-10.9116A2.1207,2.1207,0,0,1,83.1405,326.1208Z"/>
      </g>
    )
}

const D7 = () =>{
    return(
        <g>
        <path class="a" d="M122.7565,547.7728l-9.5555,84.8479a2.1208,2.1208,0,0,0,3.0177,2.1529l77.1218-36.6428a2.1208,2.1208,0,0,0,.3215-3.642l-67.5663-48.2051A2.1208,2.1208,0,0,0,122.7565,547.7728Zm17.8406,31.5417L157.71,591.5239a2.1208,2.1208,0,0,1-.269,3.6161l-20.2849,10.3342a2.1208,2.1208,0,0,1-3.0628-2.1852l3.1716-22.5435A2.1209,2.1209,0,0,1,140.5971,579.3145Z"/>
    <path class="a" d="M669.1285,298.5564l-32.9165,4.568a2.1208,2.1208,0,0,0-1.3392,3.4565l21.2453,25.5538a2.1209,2.1209,0,0,0,3.6084-.59l11.6711-30.1218A2.1208,2.1208,0,0,0,669.1285,298.5564ZM658.9732,311.1l-1.9078,4.9239a2.1208,2.1208,0,0,1-3.57.6341l-3.7759-4.2946a2.1208,2.1208,0,0,1,1.3593-3.5083l5.6838-.6293A2.1208,2.1208,0,0,1,658.9732,311.1Z"/>
    <path class="a" d="M334.3245,625.9034l-7.1828,14.0023a2.1208,2.1208,0,0,0,2.06,3.0817l15.6846-1.2847a2.1208,2.1208,0,0,0,1.59-3.2924l-8.5018-12.7176A2.1208,2.1208,0,0,0,334.3245,625.9034Zm4.07,8.709h0a2.1208,2.1208,0,0,1-1.5317,3.2868h0a2.1208,2.1208,0,0,1-2.091-3.1279h0A2.1209,2.1209,0,0,1,338.3948,634.6124Z"/>
    <path class="a" d="M570.5053,59.9908l11.3079,10.9448a2.1207,2.1207,0,0,0,3.5476-1.0748l3.3326-15.38a2.1208,2.1208,0,0,0-2.6876-2.4788l-14.64,4.4355A2.1207,2.1207,0,0,0,570.5053,59.9908Zm9.5163-1.3613h0a2.1208,2.1208,0,0,1,2.6992,2.4214h0a2.1208,2.1208,0,0,1-3.6008,1.0908h0A2.1207,2.1207,0,0,1,580.0216,58.63Z"/>
    <path class="a" d="M53.8379,390.5071,38.7738,395.06a2.1209,2.1209,0,0,0-.7873,3.6224L49.8014,409.078a2.1208,2.1208,0,0,0,3.4733-1.1418l3.2492-14.9486A2.1208,2.1208,0,0,0,53.8379,390.5071Zm-3.4413,8.9761h0a2.1208,2.1208,0,0,1-3.4288,1.18h0a2.1208,2.1208,0,0,1,.7991-3.6766h0A2.1208,2.1208,0,0,1,50.3966,399.4832Z"/>
    <path class="a" d="M662.4185,432.2021l-75.9244,39.0635a2.1208,2.1208,0,0,0-.12,3.7049l73.2387,43.8927a2.1208,2.1208,0,0,0,3.21-1.75l2.6857-82.9562A2.1208,2.1208,0,0,0,662.4185,432.2021Zm-17.6113,31.5255-.73,22.5369a2.1208,2.1208,0,0,1-3.1592,1.78l-21.2841-11.9694a2.1209,2.1209,0,0,1,.1217-3.7605L641.77,461.747A2.1207,2.1207,0,0,1,644.8072,463.7276Z"/>
    <path class="a" d="M69.22,310.4373l3.1056,46.1175a2.1207,2.1207,0,0,0,3.3533,1.58l37.54-26.967a2.1208,2.1208,0,0,0-.3334-3.641L72.24,308.3763A2.1208,2.1208,0,0,0,69.22,310.4373Zm13.92,15.6835,9.1221,4.2979a2.1208,2.1208,0,0,1,.38,3.6061l-8.69,6.6137a2.1208,2.1208,0,0,1-3.4036-1.6036l-.4324-10.9116A2.1207,2.1207,0,0,1,83.1405,326.1208Z"/>
      </g>
    )
}

const BG1 = () =>{
    return(
        <g>
    {/* <path d="M699,1V699H1V1H699m1-1H0V700H700V0Z"/> */}
    <path class="a" d="M510.84,541.92,547.676,249.846,320.5385,106.4111,112.04,313.9138l140.028,244.9626ZM316.3439,151.3971l33.9686,19.5577-7.27,41.9686-47.471,10.278L278.465,183.532Zm63.4907,67.3412,11.405,6.5665-2.4409,14.091L372.86,242.8466l-5.7436-13.319Zm58.087,271.6459,27.1306-25.7477L500.44,475.0559l-8.2364,39.8219L457.3,525.4742Z"/>
    <polygon class="a" points="587.734 417.925 533.801 442.675 550.086 328.396 606.129 351.282 587.734 417.925"/>
    <polygon class="a" points="205.12 167.443 230.224 172.427 195.645 208.247 181.845 186.082 205.12 167.443"/>
    <polygon class="a" points="146.649 251.117 160.105 253.788 141.571 272.988 134.174 261.108 146.649 251.117"/>
    <polygon class="a" points="83.878 430.829 136.606 333.552 240.233 522.206 131.039 550.806 83.878 430.829"/>
    <polygon class="a" points="349.418 629.227 326.123 565.108 454.002 555.434 440.403 634.046 349.418 629.227"/>
    <polygon class="a" points="505.647 163.81 486.558 226.538 382.676 160.19 438.488 107.607 505.647 163.81"/>
  </g>
    )
}

const BG2 = () =>{
    return(
        <g>
    <polygon class="a" points="418.705 381.322 125.361 543.318 285.897 92.357 468.325 213.489 418.705 381.322"/>
    <polygon class="a" points="552.5 299.733 401.337 383.21 444.584 249.974 578.069 213.247 552.5 299.733"/>
    <polygon class="a" points="225.7 247.72 181.152 365.233 109.173 284.082 195.279 234.462 225.7 247.72"/>
    <polygon class="a" points="224.954 98.536 260.663 142.164 213 151.977 212.988 107.394 224.954 98.536"/>
    <polygon class="a" points="179.458 151.068 168.659 161.986 155.625 141.321 173.181 147.011 179.458 151.068"/>
    <polygon class="a" points="446.976 556.644 455.197 543.674 472.341 561.082 453.975 559.269 446.976 556.644"/>
    <polygon class="a" points="598.298 384.954 550.015 400.149 544.858 373 589.441 372.988 598.298 384.954"/>
    <polygon class="a" points="167.289 583.801 181.991 535.365 207.314 546.428 182.165 583.24 167.289 583.801"/>
    <polygon class="a" points="474.216 160.95 461.286 201.449 371.578 140.466 442.856 71.214 474.216 160.95"/>
    <polygon class="a" points="344 528.051 344.22 574.316 276.155 607.746 250.346 511.776 344 528.051"/>
    <polygon class="a" points="441.729 541.645 193.259 492.301 502.135 289.86 550.774 461.058 441.729 541.645"/>
  </g>
    )
}

const BG3 = () =>{
    return(
        <g>
    <path class="a" d="M270.5385,360.8819l192.88-102.3062c15.2325-8.0795,32.3661,6.978,26.31,23.1222l-117.1394,312.27a8.2245,8.2245,0,0,1-12.365,3.8852L232.0287,509.5773a18.7277,18.7277,0,0,1-7.2458-21.0366l36.6639-116.7264A18.7279,18.7279,0,0,1,270.5385,360.8819Z"/>
    <path class="a" d="M157,470.79l.3044-58.4252c.1073-20.5843,25.36-30.4073,39.3483-15.3061l21.66,23.3823a22.6977,22.6977,0,0,1,5.3887,20.8478L212.393,487.243a22.6974,22.6974,0,0,1-38.2782,10.435l-10.656-10.9112A22.6974,22.6974,0,0,1,157,470.79Z"/>
    <path class="a" d="M408.7473,420.7708,458.5245,289.462c12.859-33.9211,57.1463-42.054,81.2182-14.9148l60.2362,67.9115a48.2527,48.2527,0,0,1-12.0062,73.8264L477.9594,479.6825a48.2525,48.2525,0,0,1-43.3706,2.4268h0A48.2528,48.2528,0,0,1,408.7473,420.7708Z"/>
    <path class="a" d="M406.6305,570.0537l-9.74-11.9a5.284,5.284,0,0,1,3.0235-8.5223l9.7365-2.0046a5.284,5.284,0,0,1,6.35,5.1741l.0036,13.9046a5.2843,5.2843,0,0,1-2.14,4.2483h0A5.2841,5.2841,0,0,1,406.6305,570.0537Z"/>
    <path class="a" d="M406.6114,523.5456l9.5664-22.8637a12.3944,12.3944,0,0,1,18.68-5.2716l31.3868,22.6168a12.3944,12.3944,0,0,1-6.3761,22.42l-30.9312,2.1762a12.3946,12.3946,0,0,1-3.2129-.1929l-10.022-1.9294A12.3944,12.3944,0,0,1,406.6114,523.5456Z"/>
    <path class="a" d="M241.9214,176.2091l-3.5687,5.63a21.6482,21.6482,0,0,1-33.7089,3.601l-6.5265-6.6271c-14.2078-14.4268-2.6-38.7226,17.5508-36.7334l10.0952.9965a21.6524,21.6524,0,0,1,5.4755,1.274h0A21.6481,21.6481,0,0,1,241.9214,176.2091Z"/>
    <path class="a" d="M123.1382,293.8135l29.3518-9.2371a7.7766,7.7766,0,0,1,9.9745,5.9666l1.7531,9.2291A7.7766,7.7766,0,0,1,156.58,309l-31.105.0081a7.7768,7.7768,0,0,1-6.2523-3.1495h0A7.7766,7.7766,0,0,1,123.1382,293.8135Z"/>
    <path class="a" d="M569.2855,168.6048l-8.9376,29.4444a7.7767,7.7767,0,0,1-10.5546,4.8675l-8.6085-3.7607a7.7766,7.7766,0,0,1-3.308-11.513l17.5461-25.6837a7.7765,7.7765,0,0,1,6.1283-3.3843h0A7.7766,7.7766,0,0,1,569.2855,168.6048Z"/>
    <path class="a" d="M209.6811,623.0046l-4.8209-.6613a25.2794,25.2794,0,0,1-19.6024-35.4523l22.8489-50.5777a25.2792,25.2792,0,0,1,37.8714-10.062l32.1063,23.2668a25.2793,25.2793,0,0,1-2.5169,42.5452l-50.1341,27.9721A25.28,25.28,0,0,1,209.6811,623.0046Z"/>
    <path class="a" d="M370.7384,114.9141l6.5158-17.6913a33.232,33.232,0,0,1,27.9034-21.5843l49.1668-4.8777a33.2319,33.2319,0,0,1,36.363,36.22l-4.9761,52.2589a33.2319,33.2319,0,0,1-49.8737,25.5275l-50.7065-29.69A33.2319,33.2319,0,0,1,370.7384,114.9141Z"/>
    <path class="a" d="M292.7559,166.9642l197.9212,36.2147c25.4994,4.6657,31.6774,38.397,9.4869,51.7973L243.5808,409.9205a28.1554,28.1554,0,0,1-41.46-15.8052L163.5839,269.14a28.1552,28.1552,0,0,1,11.0587-31.5691l97.1991-66.184A28.1558,28.1558,0,0,1,292.7559,166.9642Z"/>
  </g>
    )
}

const BG4 = () =>{
    return(
        <g>
    <rect class="a" x="200.9158" y="191.4754" width="329.6784" height="342.0491" rx="120.8842" transform="translate(-106.855 157.9939) rotate(-21.345)"/>
      <rect class="a" x="211.0382" y="112.2753" width="97.9003" height="97.9003" rx="37.1947" transform="translate(-40.8495 105.6904) rotate(-21.345)"/>
      <rect class="a" x="120.0382" y="413.2753" width="97.9003" height="97.9003" rx="37.1947" transform="translate(-156.6504 93.2149) rotate(-21.345)"/>
      <rect class="a" x="147.6796" y="206.9166" width="54.6176" height="54.6176" rx="15.5145" transform="translate(-73.2508 79.7593) rotate(-21.345)"/>
      <rect class="a" x="403.3666" y="522.6036" width="83.2435" height="83.2435" rx="27.5578" transform="translate(-174.8446 200.6707) rotate(-21.345)"/>
      <rect class="a" x="511.6796" y="237.9166" width="54.6176" height="54.6176" rx="15.5145" transform="translate(-59.5659 214.3754) rotate(-21.345)"/>
  </g>
    )
}

const BG5 = () =>{
    return(
        <g>
    <circle class="a" cx="507.4738" cy="297.2289" r="96.115"/>
      <circle class="a" cx="348.3732" cy="246.1858" r="63.1366"/>
      <circle class="a" cx="247.5712" cy="279.7014" r="37.7407"/>
      <circle class="a" cx="204.0449" cy="172.6396" r="24.6433"/>
      <circle class="a" cx="435.3613" cy="609.9378" r="17.0401"/>
      <circle class="a" cx="123.7819" cy="380.6176" r="17.0401"/>
      <circle class="a" cx="522.4347" cy="132.9568" r="17.0401"/>
      <circle class="a" cx="428.894" cy="180.3485" r="37.7407"/>
      <circle class="a" cx="530.0074" cy="449.8275" r="37.7407"/>
      <circle class="a" cx="318.4943" cy="458.3318" r="145.9495"/>
  </g>
    )
}

const BG6 = () =>{
    return(
        <g>

            <path class="a" d="M337.596,327.4528C342,353,361,377,386.869,383.6078a54.9845,54.9845,0,0,0,18.9,1.6466A56.1328,56.1328,0,0,0,425,380c32-16,55-55,95.3374-48.2877C526.7778,333.4167,533.3333,337,540,343c16,14,23,37,21,59a136.4639,136.4639,0,0,1-2.8092,21.3419,141.7748,141.7748,0,0,1-5.9767,20.3936,170.8513,170.8513,0,0,1-11.2812,22.7277,174.8873,174.8873,0,0,1-14.3979,20.7219,178.0609,178.0609,0,0,1-81.8035,56.9274c-5.5033,2.3473-11.1769,4.4365-16.937,6.2853-28.8006,9.244-59.4727,12.8059-88.9953,9.2235a93.0758,93.0758,0,0,1-18.2083-6.03c-23.8137-10.5194-46.004-29.8271-69.3436-44.2471-22.1254-13.67-42.0861-13.5755-58.1138-37.5349-10.2764-15.362-13.7447-33.3927-20.2626-50.311-7.3266-19.018-20.4218-31.5137-29.72-48.6074-18.3353-33.7065-2.58-52.3956,11.4542-86.1744,12.48-30.04,16.5963-58.976,39.8087-83.1581,38.4867-40.0946,138.2892-83.7154,185.9787-34.72,16.607,17.0615,20.2475,32.0421,16.6714,45.8133-1.7881,6.8856-5.38,13.4688-10.0559,19.8585-4.7519,5.8726-9.9409,11.59-15.1482,17.3369C358,268,341,285,336.5722,307.4807A45.7627,45.7627,0,0,0,337.596,327.4528Z"/>
      <path class="a" d="M454.41,295.0955c31.6006,66.2348-72.3182,113.6735-103.1395,47.7416C320.1712,276.31,422.6132,228.4494,454.41,295.0955Z"/>
      <path class="a" d="M556.8142,264.8042c22.6475,47.4691-51.829,81.4675-73.918,34.2155C460.6078,251.3413,534.026,217.04,556.8142,264.8042Z"/>
      <path class="a" d="M457.6888,117.7562c13.37,28.0228-30.5965,48.0934-43.6365,20.1987C400.8947,109.8086,444.2361,89.5594,457.6888,117.7562Z"/>
      <path class="a" d="M172.5387,434.31c20.6384,43.2579-47.231,74.24-67.36,31.18C84.8673,422.0411,151.7722,390.7831,172.5387,434.31Z"/>
      <path class="a" d="M572.3042,475.5293c7,14.6715-16.019,25.18-22.8462,10.5752C542.5693,471.3683,565.261,460.7667,572.3042,475.5293Z"/>
      <path class="a" d="M238.3409,135.9969c14.3831,30.1468-32.9157,51.7386-46.944,21.73C177.2419,127.4469,223.8685,105.6629,238.3409,135.9969Z"/>
      <path class="a" d="M457.6888,568.7562c13.37,28.0228-30.5965,48.0934-43.6365,20.1987C400.8947,560.8086,444.2361,540.5594,457.6888,568.7562Z"/>
  </g>
    )
}

const BG7 = () =>{
    return(
        <g>
    <g>
      <path class="a" d="M163.0921,192.82a174.4576,174.4576,0,0,0-9.7271,33.8359c-1.1134,5.7842-2.0368,11.6242-2.87,17.5033a103.8593,103.8593,0,0,1-2.62,17.8548c-5.8653,34.254-30.11,57.747-41.4722,89.9157-.4478,3.2145-1.8151,5.9732.4878,8.26,12.4422,14.1852,32.6486,17.3277,49.1527,11.7623,4.967-1.5473,10.2791-2.8281,15.73-4.0349s11.0394-2.34,16.56-3.5916a119.1172,119.1172,0,0,0,80.8725-51.9777c23.2848-35.4232,42.8914-72.67,73.1826-102.4325q4.4587-5.2584,8.4441-10.8849a132.8555,132.8555,0,0,0,13.6255-24.2341,47.797,47.797,0,0,0-2.0458-18.9994,111.387,111.387,0,0,0-5.4206-10.9437c-1.9394-3.459-3.94-6.6777-5.7854-9.5017-15.6806-21.5177-43.2356-26.0115-67.5486-27.7447a71.7234,71.7234,0,0,0-18.2394,1.0305c-14.6637,2.7363-27.9849,9.87-39.6412,19.4552-3.8927,3.1915-7.6242,6.6349-11.3974,10.0919-13.67,11.9207-26.48,23.899-39.1921,37.0652A125.1411,125.1411,0,0,0,163.0921,192.82Z"/>
      <path class="a" d="M234.0316,411.19c-5.4053,48.8937-33.9917,89.3912-44.7116,138.2263-3.5733,13.1021,2.3822,36.9241,10.72,33.3508,39.3063-16.6754,82.1859-33.3508,126.2566-29.7775,14.2932,1.1911,26.2043-7.1466,33.3509-19.0576,20.6457-34.939,38.6445-71.9954,67.8788-94.7842a102.3485,102.3485,0,0,1,24.254-14.16c22.2128-5.4011,46.0348-8.9744,51.99-35.1786,17.8665-67.8927,83.377-141.7409,44.0707-206.06-9.5288-15.4843-33.3508-17.8665-53.2389-20.3012a147.7884,147.7884,0,0,0-26.1748-.7379c-43.5155,2.586-86.0156,23.6386-119.9733,54.1092A247.0183,247.0183,0,0,0,326.5977,239.08c-23.2287,30.6075-46.853,62.5145-64.8881,97.0648-4.5088,8.6376-8.6668,17.44-12.38,26.4289a238.1694,238.1694,0,0,0-9.5071,27.515C237.6558,397.4464,235.7361,404.5034,234.0316,411.19Z"/>
      <path class="a" d="M541.2378,402.5874c-3.833,4.9853-8.533,8.3274-10.7889,15.0589-1.0242,3.3454-2.0138,6.6839-3.0065,9.9957-4.9636,16.5591-10.0059,32.45-19.8446,45.181q-2.8158,5.17-5.3058,10.6756c-10.5469,26.402-16.3508,69.6248-1.9213,83.7188,19.6585,18.3872,38.34-36.5568,51.7118-63.37,1.598-3.7,3.7952-5.5829,5.6417-8.6287,1.8914-2.638,3.85-5.1493,5.8491-7.5905,9.9911-12.1952,21.0141-22.6373,29.54-37.3123,2.5263-3.3987,3.5249-8.9157,3.5836-13.7643-.764-13.8773-6.2163-25.3821-5.6139-39.9025a60.6026,60.6026,0,0,0-.2651-10.6656c-.8247-6.7334-3.064-12.6118-7.2824-16.9826-4.9117-4.8392-12.1262.9319-17.62,7.4768-2.1307,2.3234-4.2544,4.8891-6.374,7.4327s-4.2342,5.0627-6.3568,7.3085a40.0555,40.0555,0,0,1-6.4,5.6739Q544.1345,399.5576,541.2378,402.5874Z"/>
      <path class="a" d="M229.64,398.0785c-12.0415,26.3872-14.552,62.76-45.3586,74.0842-13.8343,5.5664-28.4326-1.419-35.5681-15.1578-19.9327-42.881,24.7492-84.9705,63.7827-90.496,9.4139-.573,17.5453,3.6565,20.66,12.7847C233.5346,384.1909,232.3116,390.5084,229.64,398.0785Z"/>
      <path class="a" d="M385.3167,501.167c15.7609-20.8219,25.1075-52.2892,54.4-56.1913,13.2488-2.1752,24.7046,6.8287,28.2772,20.3015,9.0961,41.5918-38.428,69.803-73.8106,66.995-8.3843-1.3446-14.6989-6.6573-15.6435-15.29C379.1685,512.6052,381.4834,507.2943,385.3167,501.167Z"/>
      <path class="a" d="M481.2144,87.4459c-3.6481,22.454-23.8968,51.04-48.91,31.9828-9.5288-7.1466-13.1021-22.6309-8.3377-35.733,5.9555-15.4843,20.2487-28.5864,38.1152-26.2042,10.72,1.1911,19.0576,9.5288,20.6994,18.7135A26.6726,26.6726,0,0,1,481.2144,87.4459Z"/>
      <path class="a" d="M377.6171,572.9588c6.2566-10.4768,8.99-25.5706,22.3963-28.88,6.0427-1.6874,11.8239,1.9093,14.1718,7.9847,6.3505,18.8539-14.282,34.3862-30.86,34.89-3.9631-.1961-7.1676-2.3411-8.0476-6.3024A20.3259,20.3259,0,0,1,377.6171,572.9588Z"/>
      <path class="a" d="M559.3921,288.049c7.2651-14.7351,9.3035-35.2854,26.9371-41.1739,7.9268-2.9167,16.0733,1.2858,19.88,9.1852,10.5576,24.6156-15.4536,47.6869-37.6465,50.153-5.3395.1647-9.8714-2.3678-11.48-7.5887C556.9518,295.8455,557.7514,292.29,559.3921,288.049Z"/>
      <path class="a" d="M129.8683,611.0741c6.2566-10.4769,8.99-25.5707,22.3962-28.88,6.0427-1.6874,11.8239,1.9093,14.1718,7.9847,6.3506,18.8539-14.282,34.3862-30.86,34.89-3.9631-.1961-7.1676-2.3411-8.0475-6.3024A20.3249,20.3249,0,0,1,129.8683,611.0741Z"/>
      <path class="a" d="M280.6591,614.6246c12.4216-9.37,31.1795-14.9073,33.5336-32.356,1.3092-7.8916-4.0441-14.7261-12.0678-16.8681-24.7718-5.4609-41.6276,22.8264-39.99,43.9105.7928,4.9968,3.9519,8.7644,9.0941,9.3358C273.838,618.2764,277.0046,616.9024,280.6591,614.6246Z"/>
      <path class="a" d="M497.55,141.3259c8.3187-6.2749,20.8808-9.9833,22.4573-21.6687.8768-5.285-2.7082-9.862-8.0817-11.2965-16.59-3.6572-27.8779,15.2867-26.7811,29.4068.531,3.3463,2.6466,5.8694,6.09,6.2521A17.355,17.355,0,0,0,497.55,141.3259Z"/>
      <path class="a" d="M545.2417,361.4886c6.2808-4.7377,15.7656-7.5377,16.9559-16.3605.6619-3.99-2.0448-7.4461-6.102-8.5291-12.5256-2.7613-21.0485,11.5419-20.22,22.2029a5.2029,5.2029,0,0,0,4.5984,4.7205A13.1039,13.1039,0,0,0,545.2417,361.4886Z"/>
    </g>
  </g>
    )
}

const E1 = () => {
    return (
        <g>
         {/* <path d="M699,1V699H1V1H699m1-1H0V700H700V0Z"/> */}
    <rect class="a" x="636.0082" y="342.7994" width="14.3317" height="111.9487"/>
    <rect class="a" x="636.0082" y="294.2333" width="14.3317" height="33.8486"/>
    <rect class="a" x="636.0082" y="263.9414" width="14.3317" height="15.5743"/>
    <rect class="a" x="636.0082" y="242.1997" width="14.3317" height="7.0242"/>
    <rect class="a" x="25.0681" y="81.7678" width="24.2118" height="56.0616"/>
    <rect class="a" x="25.0681" y="151.8369" width="24.2118" height="26.4874"/>
    <rect class="a" x="25.0681" y="192.3318" width="24.2118" height="12.4092"/>
    <rect class="a" x="25.0681" y="218.7485" width="24.2118" height="4.7884"/>
    <rect class="a" x="390.746" y="629.9056" width="8.8561" height="89.8725" transform="translate(1070.0159 279.6678) rotate(90)"/>
    <rect class="a" x="474.7003" y="652.2777" width="8.8561" height="45.1282" transform="translate(1153.9702 195.7135) rotate(90)"/>
    <rect class="a" x="523.746" y="664.8143" width="8.8561" height="20.0552" transform="translate(1203.0159 146.6678) rotate(90)"/>
        </g>
    )
}
const E2 = () => {
    return (
        <g>
         {/* <path d="M699,1V699H1V1H699m1-1H0V700H700V0Z"/> */}
    <rect class="a" x="76.2889" y="76.2889" width="26.0742" height="26.0742" transform="translate(-16.1824 19.8548) rotate(-11.6374)"/>
    <rect class="a" x="133.1299" y="42.1299" width="16.3922" height="16.3922" transform="translate(24.9169 -44.3031) rotate(19.507)"/>
    <rect class="a" x="360.3915" y="631.2092" width="29.068" height="29.068" transform="translate(157.2775 -68.5276) rotate(13.1932)"/>
    <rect class="a" x="167.1627" y="439.9803" width="17.5257" height="17.5257" transform="translate(-230.6699 189.8988) rotate(-36.1114)"/>
    <rect class="a" x="632.4282" y="314.096" width="21.4917" height="21.4917" transform="translate(-74.9314 265.5146) rotate(-22.0737)"/>
    <rect class="a" x="520.7212" y="176.3891" width="10.9056" height="10.9056" transform="translate(-29.7681 211.0644) rotate(-22.0737)"/>
        </g>
    )
}

const E3 = () => {
    return (
        <g>
            {/* <path d="M699,1V699H1V1H699m1-1H0V700H700V0Z"/> */}
    <path class="a" d="M76.6482,67.4328l.7326,19.0959,13.3816,7.0562,16.1712-10.1823-.58-15.117L89.45,59.3721Z"/>
    <path class="a" d="M156.8461,41.7825h0l13.2168,6.9693h0L169.49,33.8212h0Z"/>
    <path class="a" d="M250.1651,623.1852,233.55,631.9719l-.5617,15.1177,15.917,9.9954,13.3731-7.0724.6978-18.7821Z"/>
    <path class="a" d="M496.544,519.0323l-2.5321,1.3391-.5617,15.1177,2.4258,1.5232,13.3731-7.0724.1063-2.8623Z"/>
    <path class="a" d="M50.93,177.845l-2.8084-.563L38.13,188.641l.9166,2.7136,14.833,2.9736,1.8918-2.1507Z"/>
    <path class="a" d="M559.7191,225.5045l-9.5057-5.0956-12.8564,7.9734.34,10.78L551.03,246.31l9.1657-5.6845Z"/>
    <path class="a" d="M626.0568,376.7713l-9.6384,16.6941,7.5641,13.1013h19.2766l7.5641-13.1013-9.6383-16.6941Z"/>
        </g>
    )
}

const E4 = () => {
    return (
        <g>
            {/* <path d="M699,1V699H1V1H699m1-1H0V700H700V0Z"/> */}
    <polygon class="a" points="99.969 79.889 99.858 100.884 118.096 90.482 99.969 79.889"/>
    <polygon class="a" points="237.325 633.493 214.529 645.303 236.155 659.14 237.325 633.493"/>
    <polygon class="a" points="648.515 562.53 629.34 572.464 647.531 584.103 648.515 562.53"/>
    <polygon class="a" points="123.81 237.823 98.045 251.171 122.488 266.811 123.81 237.823"/>
    <polygon class="a" points="593.009 294.974 572.845 305.42 591.974 317.659 593.009 294.974"/>
    <polygon class="a" points="151.373 126.189 140.481 131.832 150.814 138.444 151.373 126.189"/>
    <polygon class="a" points="593.409 376.922 596.448 415.966 628.742 393.812 593.409 376.922"/>
        </g>
    )
}

const E5 = () => {
    return (
        <g>
            {/* <path d="M699,1V699H1V1H699m1-1H0V700H700V0Z"/> */}
    <path class="a" d="M88.075,91.047l4.3625,9.7a4.6622,4.6622,0,0,0,8.0339.814l6.2195-8.6283a4.6621,4.6621,0,0,0-3.312-7.3645l-10.582-1.0722A4.6622,4.6622,0,0,0,88.075,91.047Z"/>
    <path class="a" d="M143.075,570.047l4.3625,9.7a4.6622,4.6622,0,0,0,8.0339.814l6.22-8.6283a4.6621,4.6621,0,0,0-3.312-7.3645l-10.582-1.0722A4.6622,4.6622,0,0,0,143.075,570.047Z"/>
    <path class="a" d="M550.2507,444.3937l.7935,1.7645a4.6621,4.6621,0,0,0,8.0339.8139l1.1314-1.5694a4.6622,4.6622,0,0,0-3.3121-7.3646l-1.9248-.195A4.6621,4.6621,0,0,0,550.2507,444.3937Z"/>
    <path class="a" d="M67.2507,199.3937l.7935,1.7645a4.6621,4.6621,0,0,0,8.0339.8139l1.1314-1.5694a4.6622,4.6622,0,0,0-3.3121-7.3646l-1.9248-.195A4.6621,4.6621,0,0,0,67.2507,199.3937Z"/>
    <path class="a" d="M555.9471,307.16l-3.7745,9.944a4.6621,4.6621,0,0,0,5.1053,6.2564l10.4989-1.7032a4.6621,4.6621,0,0,0,2.8656-7.5495l-6.7245-8.2408A4.6621,4.6621,0,0,0,555.9471,307.16Z"/>
    <path class="a" d="M244.41,660.7l8.8828-15.5051a7.7846,7.7846,0,0,0-3.8622-11.0969l-12.5316-5.0153a7.7845,7.7845,0,0,0-10.5568,8.59l3.6489,20.52A7.7846,7.7846,0,0,0,244.41,660.7Z"/>
    <path class="a" d="M615.3805,392.9717l-6.0512-9.381a10.1261,10.1261,0,0,0-17.5176.864l-5.0986,9.931a10.1261,10.1261,0,0,0,9.507,14.7387l11.15-.55A10.1261,10.1261,0,0,0,615.3805,392.9717Z"/>
        </g>
    )
}

const E6 = () => {
    return (
        <g>
            {/* <path d="M699,1V699H1V1H699m1-1H0V700H700V0Z"/> */}
    <circle class="a" cx="368.7317" cy="622.0569" r="12.6023"/>
    <circle class="a" cx="82.7236" cy="64.7236" r="11.9209"/>
    <circle class="a" cx="117.7236" cy="116.7236" r="5.987"/>
    <circle class="a" cx="70.7236" cy="492.7236" r="5.987"/>
    <circle class="a" cx="613.4557" cy="422.2459" r="12.184"/>
    <circle class="a" cx="493.8712" cy="207.1541" r="7.0157"/>
        </g>
    )
}

const E7 = () => {
    return (
        <g>
            {/* <path d="M699,1V699H1V1H699m1-1H0V700H700V0Z"/> */}
    <circle class="a" cx="377.8729" cy="613.2516" r="9.0546"/>
    <circle class="a" cx="82.7236" cy="64.7236" r="13.1122"/>
    <circle class="a" cx="620.2764" cy="430.813" r="11.5038"/>
    <circle class="a" cx="582.2764" cy="393.813" r="7.582"/>
    <circle class="a" cx="237.2764" cy="476.813" r="7.582"/>
    <circle class="a" cx="400.3817" cy="591.3719" r="3.6666"/>
    <circle class="a" cx="498.3817" cy="536.3719" r="3.6666"/>
    <circle class="a" cx="193.3817" cy="521.3719" r="3.6666"/>
    <circle class="a" cx="45.2764" cy="83.813" r="4.9728"/>
    <circle class="a" cx="142.2764" cy="27.813" r="3.9499"/>
    <circle class="a" cx="342.2764" cy="73.813" r="3.9499"/>
    <circle class="a" cx="160.2764" cy="665.813" r="3.9499"/>
    <circle class="a" cx="653.2764" cy="492.813" r="3.9499"/>
        </g>
    )
}


export {
    A1, A2, A3, A4, A5, A6, A7, A8, A9,
    B1, B2, B3, B4, B5, B6, B7, 
    C1, C2 ,C3, C4, C5, C6, C7, 
    D1, D2, D3, D4, D5, D6, D7,
    E1, E2, E3, E4, E5, E6, E7,
    BG1, BG2, BG3, BG4, BG5, BG6, BG7
}
